@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-user-select: none;
  /* color:var(--secondary-main-color) */
  font-family: "Quicksand", sans-serif !important;
}
::-webkit-scrollbar {
  width: 0px;
}
.news-headImage{
  width: 100%;
  max-height: 500px;
  margin-bottom: 10px;
}
.tooltip {
  border-radius: 0.25rem;
  background: #26313c;
  color: #fff;
  padding: 1rem;
  box-shadow: 15px 30px 40px 5px rgba(0, 0, 0, 0.5);
  text-align: center;
}
.padding {
  padding: 3rem;
}
.ql-editor{
    max-height:fit-content;
    min-height: 250px;
}

@layer components {
  .searchInput {
    @apply hidden sm:inline-flex bg-transparent ml-1 outline-none  flex-shrink w-9/12;
  }
  .topnav__search {
    @apply flex ml-2 items-center rounded-full bg-gray-50  w-7/12 relative h-4/6;
  }
  .topnav__Search-button {
    @apply h-full rounded-full px-2 flex items-center justify-center mr-1;
  }
  .topnav__Search-button:hover {
    @apply bg-gray-100;
  }
  .Info {
    @apply flex justify-around;
  }

}
